import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./nav.css";
import { menuList } from "../../menu-list";
import { useAnalyticsEvent } from "../analytics/analytics-service";

const Navbar = () => {
  const [path, setPath] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const eventTracker = useAnalyticsEvent("Nav Bar");
  const LocationChange = (e: any) => {
    eventTracker(e);
    setPath(e);
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };
  const isLocationActive = (path: string) => {
    if (
      path &&
      window.location &&
      window.location.pathname &&
      window.location.pathname.includes(path)
    ) {
      return true;
    }
    return false;
  };
  const isHomeActive = () => {
    if (
      window.location &&
      window.location.pathname &&
      window.location.pathname === "/"
    ) {
      return true;
    }
    return false;
  };
  const getMenuList = (menulist: Array<any>) => {
    if (menuList && menuList.length > 0) {
      const displayCount = Math.floor(window.innerWidth / 200);
      if (menuList.length > displayCount) {
        return JSON.parse(JSON.stringify(menuList)).splice(0, displayCount);
      }
    }
    return menuList;
  };
  const pendingMenuList = (menulist: Array<any>) => {
    if (menuList && menuList.length > 0) {
      const displayCount = Math.floor(window.innerWidth / 200);
      if (menuList.length > displayCount) {
        return JSON.parse(JSON.stringify(menuList)).splice(
          displayCount,
          menuList.length
        );
      }
    }
    return [];
  };
  const isMenuMore = (menulist: Array<any>) => {
    if (menuList && menuList.length > 0) {
      const displayCount = Math.floor(window.innerWidth / 200);
      if (menuList.length > displayCount) {
        return true;
      }
    }
    return false;
  };
  return (
    <>
      <div className="navbar">
        <div
          className={
            isHomeActive() || isLocationActive("json")
              ? "navbar-item navbar-active"
              : "navbar-item navbar-in-active"
          }
        >
          <Link onClick={() => LocationChange("json")} to="/json">
            JSON
          </Link>
        </div>
        {!showDropdown &&
          getMenuList(menuList).map((nav: any, indv: number) => {
            return (
              <div
                key={indv + "navlist"}
                className={
                  isLocationActive(nav.id)
                    ? "navbar-item navbar-active"
                    : "navbar-item navbar-in-active"
                }
              >
                <Link onClick={() => LocationChange(nav.id)} to={nav.path}>
                  {nav.displayName}
                </Link>
              </div>
            );
          })}
        {!showDropdown && isMenuMore(menuList) && (
          <div className="navbar-item navbar-in-active">
            <Link onClick={() => handleDropdownToggle()} to="">
              &#8594;
            </Link>
          </div>
        )}
        {showDropdown && isMenuMore(menuList) && (
          <div className="navbar-item navbar-in-active">
            <Link onClick={() => handleDropdownToggle()} to="">
              &#8592;
            </Link>
          </div>
        )}

        {showDropdown &&
          pendingMenuList(menuList).map((nav: any, indv: number) => {
            return (
              <div
                key={indv + "navlist"}
                className={
                  isLocationActive(nav.id)
                    ? "navbar-item navbar-active"
                    : "navbar-item navbar-in-active"
                }
              >
                <Link onClick={() => LocationChange(nav.id)} to={nav.path}>
                  {nav.displayName}
                </Link>
              </div>
            );
          })}
        <div className="navbar-logo">
          <a
            href="https://meyostech.com/"
            target="_blank"
            title="Meyos Technology"
          >
            <img
              src="/logo.png"
              alt="Meyos Technology"
              style={{ width: "150px" }}
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default Navbar;
