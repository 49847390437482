import { ApiHomeLazy } from "./components/api-form/api-home-lazy";
import { Base64Lazy } from "./components/base64-form/base64-lazy";
import { CaseHomeLazy } from "./components/case-form/case-home-lazy";
import { GraphqlHomeLazy } from "./components/graphql-form/graphql-home-lazy";
import { JwtGeneratorLazy } from "./components/jwt-generator/jwt-generator-lazy";
import { QRCodeGeneratorLazy } from "./components/qr-generator/qr-generator-lazy";
import { ResponsiveLazy } from "./components/responsive/responsive-lazy";
import { WorldClockLazy } from "./components/world-clock/world-clock-lazy";

export const menuList = [
  {
    id: "responsive",
    displayName: "Responsive",
    path: "/responsive",
    component: ResponsiveLazy,
  },
  {
    id: "api",
    displayName: "API",
    path: "/api",
    component: ApiHomeLazy,
  },
  {
    id: "graphql",
    displayName: "GraphQL",
    path: "/graphql",
    component: GraphqlHomeLazy,
  },
  {
    id: "case",
    displayName: "Case Converter",
    path: "/case",
    component: CaseHomeLazy,
  },
  {
    id: "base64",
    displayName: "Base64",
    path: "/base64",
    component: Base64Lazy,
  },
  {
    id: "worldclock",
    displayName: "World Clock",
    path: "/worldclock",
    component: WorldClockLazy,
  },
  {
    id: "qrcode",
    displayName: "QR Code",
    path: "/qrcode",
    component: QRCodeGeneratorLazy,
  },
  {
    id: "jwtgenerator",
    displayName: "JWT",
    path: "/jwtgenerator",
    component: JwtGeneratorLazy,
  },
];
