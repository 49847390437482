import React, { Suspense } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { JsonHomeLazy } from "./components/json/json-home-lazy";
import NavbarLazy from "./components/nav/nav";
import { menuList } from "./menu-list";
import { Googleinitialize } from "./components/analytics/analytics-service";
import AdComponent from "./components/ad-component";

// const MyContext = React.createContext();
function App() {
  Googleinitialize();
  return (
    <div className="App">
      <div className="App-route">
        <React.StrictMode>
          <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>
              <NavbarLazy></NavbarLazy>
              <div className="AppContent">
                <Routes>
                  {menuList.map((nav: any, indv: number) => {
                    return (
                      <Route
                        key={indv + "routelist"}
                        path={nav.path}
                        Component={nav.component}
                      />
                    );
                  })}
                  <Route path="*" Component={JsonHomeLazy} />
                </Routes>
              </div>
            </Suspense>
          </BrowserRouter>
        </React.StrictMode>
      </div>
      <div className="App-ad">
        <AdComponent></AdComponent>
      </div>
    </div>
  );
}

export default App;
