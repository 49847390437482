import React from "react";
import ReactGA from "react-ga";

export const Googleinitialize = () => {
  ReactGA.initialize("UA-274927969-1");
};

export const useAnalyticsEvent = (category: string) => {
  const eventTracker = (action = "test", label = "test") => {
    ReactGA.event({ category, action, label });
  };
  return eventTracker;
};
