import React from 'react';

export default class AdComponent extends React.Component {
  componentDidMount () {
    ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
  }

render () {
    return (
        <ins className='adsbygoogle'
          style={{ display: 'block' }}
          data-ad-client='ca-pub-4493706779642472'
          data-ad-slot='5468447232'
          data-ad-format='auto' />
    );
  }
}